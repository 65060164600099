

 .searchBox[type="text"]{
    outline: none;
    padding-left: 20px;
  }

  .searchBox ::placeholder {
    padding-left: 20px;
    opacity: 1; /* Firefox */
  }