@font-face {
    font-family: "NotoSansLao";
    src: url("./fonts/NotoSansLao-Regular.ttf") format("truetype");
}

* {
    font-family: "NotoSansLao";

}

*,
body,
.Dialogbox,
label,
div {
    margin: 0;
    font-family: "NotoSansLao";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    padding: 0;
    margin: 0;
}


.h1 {
    font-size: 24px;
    color: #6D6D6D;
    font-weight: 700;
}

.h2 {
    font-size: 20px;
    color: #6D6D6D;
    font-weight: 700;
}

.h2-1 {
    font-size: 20px;
    color: #7C7C7C;
    font-weight: 400;
}


.h3 {
    font-size: 18px;
    color: #6D6D6D;
    font-weight: 700;
}

.h4 {
    font-size: 16px;
    color: #6D6D6D;
    font-weight: 700;
}

.h5 {
    font-size: 14px;
    color: #6D6D6D;
    font-weight: 700;
}

.h6 {
    font-size: 12px;
    color: #6D6D6D;
    font-weight: 700;
}



.price {
    font-size: 14px;
    color: #6D6D6D;
    font-weight: 700;
}

.sub-price {
    font-size: 14px;
    color: #7C7C7C;
    font-weight: 400;
}

.detail {
    font-size: 14px;
    color: #6D6D6D;
    font-weight: 400;
}








.href-link {
    color: #0080ff;
    cursor: pointer;
}

.infoBox[type="text"] {
    border: 1px solid #ffffff;
    outline: none;
    background-color: transparent;
    height: 50px;
    min-width: 100%;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;

}

.infoBox::placeholder {
    text-align: center;
    color: #ffffff !important;
    font-size: 15px;
}

.infoBoxNumber[type="number"] {
    border: 1px solid #ffffff;
    outline: none;
    background-color: transparent;
    height: 50px;
    min-width: 100%;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;

}

.infoBoxNumber::placeholder {
    text-align: center;
    color: #ffffff !important;
    font-size: 15px;
}

/* ::placeholder {
    color: #ffffff !important;
} */


.cancelBtn {
    /* padding: 5px; */
    width: 100px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    color: #9A9A9A;
    background: rgba(236, 236, 236, 0.56);
    cursor: pointer;
    
}


.confirmBtn {
    /* padding: 5px; */
    width: 100px;
    background-color: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    color: #27C990;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;

}